import { api } from './api'
import { DealAggregate } from 'Models/deal-aggregate';
import { DealSummary } from 'Models/deal-summary';
import { PagedResult } from 'Models/paged-result';
import { PaginationFilterSortParams } from 'Models/pagination-filter-sort-params';

export const dealApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDeals: build.mutation<PagedResult<Array<DealSummary>>, Partial<{ companyId: string, params: PaginationFilterSortParams }>>({
            query({ companyId, ...request }) {
                return {
                    url: `integration/core/company/${companyId}/paged-deals`,
                    method: 'POST',
                    body: request.params,
                }
            }
        }),
        getDeal: build.query<DealAggregate, { companyId: string, dealId: string }>({
            query: ({ companyId, dealId }) => ({ url: `integration/core/company/${companyId}/deal/${dealId}` }),
            providesTags: ['Deal'],
        })
    })
});

export const {
    useGetDealsMutation,
    useGetDealQuery
} = dealApi
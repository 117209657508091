import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PageLoader } from "Components/PageLoader";
import React from "react";

export interface AuthenticationGuardProps{
    component: React.ComponentType<object>;
}

export const AuthenticationGuard = (props: AuthenticationGuardProps) => {
  const Component = withAuthenticationRequired(props.component, {
    onRedirecting: () => (
      <div>
        <PageLoader />
      </div>
    ),
  });

  return <Component />;
};
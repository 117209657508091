import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, Box, Checkbox, FormControlLabel, FormGroup, Link, TextField, Typography, styled } from "@mui/material";
import { useGetDealQuery } from "Services/deal";
import { useAppSelector } from "State/hooks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContactTimelineOptions, HorizontalContactTimeline } from "Components/HorizontalContactTimeline";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ContactView } from "Models/contact-view";
import { v4 } from "uuid";
import { DealAggregate } from "Models/deal-aggregate";
import { stringAvatar } from "Helpers/color-helper";
import MuiTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
    Filler,
    ScriptableContext,
    ChartOptions,
    TimeScale
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { compareAsc, differenceInDays, format, subDays } from 'date-fns'
import "chartjs-adapter-date-fns";
import { LifecycleStageHistory } from 'Models/lifecycle-stage-history';
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
    Filler,
);

export interface MainTimelineProps {
    deal: DealAggregate;
    rangeMinDate: Date;
    rangeMaxDate: Date;
    selectedContacts: Array<ContactView>;
    months: Array<string>;
}

interface Stage extends LifecycleStageHistory {
    width: number;
    color: string;
    left?: number;
}

interface Point {
    x: string;
    y: number;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Timeline() {
    let { dealId } = useParams();
    const [fromDateRequiredError, setFromDateRequiredError] = useState(false);
    const [fromDateAfterToDateError, setFromDateAfterToDateError] = useState(false);
    const [toDateRequiredError, setToDateRequiredError] = useState(false);
    const [toDateBeforeFromDateError, setToDateBeforeFromDateError] = useState(false);
    const [marketingTouchpointsEnabled, setMarketingTouchpointsEnabled] = useState<boolean | undefined>(true);
    const [salesTouchpointsEnabled, setSalesTouchpointsEnabled] = useState<boolean | undefined>(true);
    const [prospectEngagementEnabled, setProspectEngagementEnabled] = useState<boolean | undefined>(true);
    const [contactViews, setContactViews] = useState<Array<ContactView>>([]);
    const companyId = useAppSelector((state) => state.company.companyId);
    const { data: deal, refetch: fetchDeal } = useGetDealQuery({ companyId: companyId, dealId: dealId ?? '' }, { skip: !dealId || !companyId });
    const [selectedContactViews, setSelectedContactViews] = useState<Array<ContactView>>([]);
    const [data, setData] = useState<ChartData<"line", Array<Point>, unknown>>();
    const [stageData, setStageData] = useState<Array<Stage>>([]);
    const [options, setOptions] = useState<ChartOptions<"line">>();
    const [startDate, setStartDate] = useState<undefined | Date>(undefined);
    const [endDate, setEndDate] = useState<undefined | Date>(undefined);
    const [contactTimelineOptions, setContactTimelineOptions] = useState<ContactTimelineOptions>({ marketingTouchpointsEnabled: true, salesTouchpointsEnabled: true });

    useEffect(() => {
        if (deal?.rangeStartDate && deal?.rangeEndDate) {
            setStartDate(new Date(deal.rangeStartDate));
            setEndDate(new Date(deal.rangeEndDate));
        }
    }, [deal?.rangeStartDate, deal?.rangeEndDate])

    useEffect(() => {
        if (deal && startDate && endDate) {
            // Set min and max dates
            const contactViews = deal.contacts.map((contact) => {
                return {
                    ...contact,
                    hide: false,
                    viewId: v4(),
                    avatarProps: stringAvatar(`${contact.firstName} ${contact.lastName}`)
                }
            });
            contactViews.sort((a, b) => a.firstName?.localeCompare(b?.firstName));
            setSelectedContactViews([...contactViews]);
            setContactViews([...contactViews]);

            const options: ChartOptions<"line"> = {
                scales: {
                    x: {
                        type: "time",
                        time: {
                            unit: 'month'
                        },
                        grid: {
                            display: false
                        },
                        ticks: {
                            align: 'inner'
                        },
                        min: format(subDays(new Date(startDate).getTime(), 1), 'yyyy-MM-dd HH:mm:ss'),
                        max: format(new Date(endDate).getTime(), 'yyyy-MM-dd HH:mm:ss'),
                    },
                    y: {
                        display: false,
                        grid: {
                            display: false
                        },
                        max: Math.max(...[...deal.marketingTouchpoints.map((touchpoint) => touchpoint.count), ...deal.salesTouchpoints.map((touchpoint) => touchpoint.count)]) + 1
                    }
                },
                responsive: true,
                layout: {
                    padding: {
                        left: 0,
                        right: 0
                    }
                },
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                        position: 'top' as const,
                        align: 'start' as const
                    },
                    title: {
                        display: false,
                        text: 'Chart.js Line Chart',
                    },
                },
            };
            const graphData: ChartData<"line", Array<Point>, string> = {
                datasets: [
                    {
                        hidden: !marketingTouchpointsEnabled,
                        order: 1,
                        label: 'Marketing touchpoints',
                        data: [...deal.marketingTouchpoints.map((touchpoint) => ({ x: format(new Date(touchpoint.date).getTime(), 'yyyy-MM-dd HH:mm:ss'), y: touchpoint.count })).sort((a, b) => (new Date(a.x).getTime() - new Date(b.x).getTime()))],
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                    {
                        hidden: !salesTouchpointsEnabled,
                        order: 2,
                        label: 'Sales touchpoints',
                        data: [...deal.salesTouchpoints.map((touchpoint) => ({ x: format(new Date(touchpoint.date).getTime(), 'yyyy-MM-dd HH:mm:ss'), y: touchpoint.count })).sort((a, b) => (new Date(a.x).getTime() - new Date(b.x).getTime()))],
                        borderColor: 'rgba(35, 151, 74, 0.75)',
                        backgroundColor: 'rgba(35, 151, 74, 0.75)',
                    },
                    {
                        hidden: !prospectEngagementEnabled,
                        order: 3,
                        fill: true,
                        label: 'Prospect engagement',
                        data: [...deal.marketingTouchpoints.map((touchpoint) => ({ x: format(new Date(touchpoint.date).getTime(), 'yyyy-MM-dd HH:mm:ss'), y: touchpoint.count })).sort((a, b) => (new Date(a.x).getTime() - new Date(b.x).getTime()))],
                        borderWidth: 0,
                        pointStyle: false,
                        backgroundColor: (context: ScriptableContext<"line">) => {
                            const ctx = context.chart.ctx;
                            const gradient = ctx.createLinearGradient(0, 0, 0, 500);
                            gradient.addColorStop(0, "rgba(250, 6, 240, 0.63)");
                            gradient.addColorStop(1, "white");
                            return gradient;
                        },
                    },
                ],
            };
            const stages = new Array<Stage>();
            const sortedStages = [...deal.stageHistory].sort((a, b) => (new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()));
            let rangeEndRunningDate = new Date(endDate).getTime();
            sortedStages.forEach((stage) => {
                const diff = differenceInDays(rangeEndRunningDate, new Date(stage.timestamp).getTime());
                stages.push({
                    ...stage,
                    width: ((diff / differenceInDays(new Date(endDate), subDays(new Date(startDate).getTime(), 1))) * 100),
                    color: `hsl(${((diff / differenceInDays(new Date(endDate), subDays(new Date(startDate).getTime(), 1)))) * 360}, 100%, 75%)}`
                });
                rangeEndRunningDate = new Date(stage.timestamp).getTime();
            });
            setStageData(stages.reverse());
            setData(graphData);
            setOptions(options);
            setContactTimelineOptions({
                marketingTouchpointsEnabled,
                salesTouchpointsEnabled
            });
        }
    }, [deal, endDate, marketingTouchpointsEnabled, prospectEngagementEnabled, salesTouchpointsEnabled, startDate])

    const handleValueChange = (event: React.SyntheticEvent, values: ContactView[], reason: AutocompleteChangeReason, details?: AutocompleteChangeDetails<ContactView> | undefined) => {
        if (details) {
            if (reason === 'removeOption' && details) {
                const newValues = values.filter((val) => {
                    return details.option.viewId !== val.viewId;
                });
                newValues.sort((a, b) => a.firstName.localeCompare(b.firstName));
                setSelectedContactViews(newValues);
            } else if (reason === 'selectOption') {
                values.sort((a, b) => a.firstName.localeCompare(b.firstName));
                setSelectedContactViews(values);
            }
        }
    }

    const marketingTouchpointsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMarketingTouchpointsEnabled(event.target.checked);
        setContactTimelineOptions({
            marketingTouchpointsEnabled: event.target.checked,
            salesTouchpointsEnabled,
        });
    };


    const salesTouchpointsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSalesTouchpointsEnabled(event.target.checked);
        setContactTimelineOptions({
            marketingTouchpointsEnabled,
            salesTouchpointsEnabled: event.target.checked
        });
    };


    const prospectEngagementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProspectEngagementEnabled(event.target.checked);
    };

    const onFromDateChange = (fromDate: Date | null) => {
        if (fromDate && !isNaN(fromDate.getTime()) && endDate) {
            if (compareAsc(fromDate, endDate) === 1) {
                setFromDateAfterToDateError(true);
            } else {
                setFromDateRequiredError(false);
                setFromDateAfterToDateError(false);
                setStartDate(fromDate);
            }
        } else {
            setFromDateRequiredError(true);
        }
    }

    const onToDateChange = (toDate: Date | null) => {
        if (toDate && !isNaN(toDate.getTime()) && startDate) {
            if (compareAsc(startDate, toDate) === 1) {
                setToDateBeforeFromDateError(true);
            } else {
                setToDateBeforeFromDateError(false);
                setToDateRequiredError(false);
                setEndDate(toDate);
            }
        } else {
            setToDateRequiredError(true);
        }
    }

    const resetDates = () => {
        if (deal) {
            setStartDate(new Date(deal.rangeStartDate));
            setEndDate(new Date(deal.rangeEndDate));
            setToDateBeforeFromDateError(false);
            setToDateRequiredError(false);
            setFromDateRequiredError(false);
            setFromDateAfterToDateError(false);
        }
    }

    return <>{deal && data && startDate && endDate &&
        <Box m="20px" marginRight="20px">
            <Box position="sticky" top="0" zIndex="1000" sx={{ background: "white" }}>
                <Box display="flex">
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h4" gutterBottom>
                                {deal.summary.name}
                            </Typography>
                            <Box display="flex" height="50px" justifyContent="center">
                                <Box display="flex" flexDirection="column" justifyContent="space-evenly">
                                    <Typography><strong>Owner:</strong> {deal.summary.owner}</Typography>
                                    <Typography><strong>Type:</strong> {deal.summary.dealType}</Typography>
                                </Box>
                                <Box marginLeft="50px" marginRight="50px" borderLeft="2px solid gray" height="100%"></Box>
                                <Box display="flex" flexDirection="column" justifyContent="space-evenly">
                                    <Typography><strong>Create date:</strong> {format(new Date(deal.summary.createDate), 'MM/dd/yyyy')}</Typography>
                                    <Typography><strong>Close date:</strong> <span style={{ color: "#07B642" }}>{(deal.summary.closeDate) ? format(new Date(deal.summary.closeDate), 'MM/dd/yyyy') : ''}</span></Typography>
                                </Box>
                                <Box marginLeft="50px" marginRight="50px" borderLeft="2px solid gray" height="100%"></Box>
                                <Box display="flex" flexDirection="column" justifyContent="space-evenly">
                                    <Typography><strong>Amount:</strong> ${deal.summary.amount}</Typography>
                                    <Typography>&nbsp;</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flex="1">

                        <FormGroup sx={{ flexDirection: "column", justifyContent: "space-between", flex: "1", alignContent: "flex-end" }}>
                            <Box display="flex" alignItems="center" flex="1">
                                <Typography component="div" paddingRight="5px"><strong>Date Range</strong>
                                    <IconButton aria-label="delete" onClick={resetDates} size="small" title="Reset dates">
                                        <RestartAltIcon />
                                    </IconButton></Typography>
                                <Box display="flex" flex="1">
                                    <Box display="flex" paddingRight="10px" flex="1" flexDirection="column">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="From Date"
                                                slotProps={{ textField: { size: 'small', fullWidth: true, required: true } }}
                                                minDate={subDays(new Date(deal.rangeStartDate).getTime(), 1)}
                                                value={startDate}
                                                onChange={onFromDateChange}
                                                maxDate={new Date(endDate)} />
                                        </LocalizationProvider>
                                        {fromDateRequiredError && <Box color="red">From date is required.</Box>}
                                        {fromDateAfterToDateError && <Box color="red">From cannot be after To date.</Box>}
                                    </Box>
                                    <Box display="flex" paddingRight="10px" flex="1" flexDirection="column">
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="To Date"
                                                value={endDate}
                                                slotProps={{ textField: { size: 'small', fullWidth: true, required: true } }}
                                                minDate={startDate}
                                                onChange={onToDateChange}
                                                maxDate={new Date(deal.rangeEndDate)} />
                                        </LocalizationProvider>
                                        {toDateRequiredError && <Box color="red">To date is required.</Box>}
                                        {toDateBeforeFromDateError && <Box color="red">To cannot be before From date.</Box>}
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box display="inline-flex" height="32px" justifyContent="flex-end" alignItems="center" flexShrink="0" borderRadius="30px" sx={{ backgroundColor: '#0690F9' }} padding="10px" marginRight="10px">
                                    <MuiTooltip title={<Box><Typography>All inbound calls and emails</Typography></Box>}><FormControlLabel sx={{ color: "white" }} control={<Checkbox
                                        onChange={marketingTouchpointsChange}
                                        checked={marketingTouchpointsEnabled}
                                        sx={{
                                            color: "white",
                                            '&.Mui-checked': {
                                                color: "white",
                                            }
                                        }}
                                    />} label="MARKETING TOUCHPOINTS" /></MuiTooltip>
                                </Box>
                                <Box display="inline-flex" height="32px" justifyContent="flex-end" alignItems="center" flexShrink="0" borderRadius="30px" sx={{ backgroundColor: '#5AB177' }} padding="10px" marginRight="10px">
                                    <MuiTooltip title={<Box><ul style={{paddingLeft: "15px"}}>
                                        <li><Typography>All outbound calls and emails</Typography></li>
                                        <li><Typography>All completed meetings</Typography></li>
                                    </ul></Box>}><FormControlLabel sx={{ color: "white" }} control={<Checkbox
                                        onChange={salesTouchpointsChange}
                                        checked={salesTouchpointsEnabled}
                                        sx={{
                                            color: "white",
                                            '&.Mui-checked': {
                                                color: "white",
                                            },
                                        }}
                                    />} label="SALES TOUCHPOINTS" /></MuiTooltip>
                                </Box>
                                <Box display="inline-flex" height="32px" justifyContent="flex-end" alignItems="center" flexShrink="0" borderRadius="30px" sx={{ backgroundColor: 'rgba(250, 6, 240, 0.63)' }} padding="10px" marginRight="10px">
                                    <FormControlLabel sx={{ color: "white" }} control={<Checkbox
                                        checked={prospectEngagementEnabled}
                                        onChange={prospectEngagementChange}
                                        sx={{
                                            color: "white",
                                            '&.Mui-checked': {
                                                color: "white",
                                            },
                                        }}
                                    />} label="PROSPECT ENGAGEMENT" />
                                </Box>
                            </Box>
                        </FormGroup>
                    </Box>
                </Box>
                <hr />
                <Box marginLeft="40px" marginRight="10px">
                    <Box display="flex" flexDirection="column">
                        <Box display="flex">
                            <Box sx={{ backgroundColor: "#756798" }} display="flex" flexGrow="1"></Box>
                            {stageData.map((stage, index) => {
                                return <MuiTooltip title={`${format(new Date(stage.timestamp), 'MM/dd/yyyy')} - ${stage.stageTitle}`} placement="bottom" arrow key={index}>
                                    <Box
                                        display="flex"
                                        textOverflow="ellipsis"
                                        overflow="hidden"
                                        whiteSpace="nowrap"
                                        flex="0"
                                        flexGrow="0"
                                        flexBasis={`${stage.width}%`}
                                        color="white"
                                        alignContent="center"
                                        justifyContent="center"
                                        fontWeight="800"
                                        sx={{ backgroundColor: `${stage.color}` }}>
                                        {stringAvatar(stage.stageTitle).children}
                                    </Box>
                                </MuiTooltip>
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box marginLeft="40px" marginRight="10px">
                <Box>
                    <Line options={options} data={data} style={{ width: "100%", height: "468px" }} />
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" alignContent="center" marginTop="15px">
                <Typography component="div" alignItems="center" display="flex" gutterBottom fontSize="16px" fontStyle="normal" fontWeight="500" letterSpacing="2px" lineHeight="20px" color="#211540">Contacts</Typography>
                <Box>
                    <Autocomplete
                        value={selectedContactViews}
                        onChange={handleValueChange}
                        isOptionEqualToValue={(contact, value) => (contact.viewId === value.viewId)}
                        multiple
                        options={contactViews}
                        limitTags={2}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.firstName + " " + option.lastName}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.firstName + " " + option.lastName}
                            </li>
                        )}
                        style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField {...params} label="Select contacts" placeholder="Contacts" />
                        )}
                    />
                </Box>
            </Box>

            <hr />
            <Box marginRight="10px" marginTop="20px">
                {selectedContactViews.map((contact: ContactView, index) => {
                    return <Box marginBottom="10px" key={index}>
                        <HorizontalContactTimeline
                            contact={contact}
                            options={contactTimelineOptions}
                            dealCreateDate={new Date(deal.summary.createDate)}
                            rangeMaxDate={new Date(endDate)}
                            rangeMinDate={new Date(startDate)}></HorizontalContactTimeline>
                    </Box>
                })}
            </Box>

        </Box>}
    </>;
}
import { createContext, useState, useMemo } from 'react';
import { ThemeOptions, createTheme } from '@mui/material/styles';


export const tokens = (mode: 'dark' | 'light') => ({
    ...(mode === 'dark') ? {
        gray: {
            100: "#e2e5e8",
            200: "#c6cbd1",
            300: "#a9b0ba",
            400: "#8d96a3",
            500: "#707c8c",
            600: "#5a6370",
            700: "#434a54",
            800: "#2d3238",
            900: "#16191c"
        },
        primary: {
            100: "#ced2d7",
            200: "#9da4af",
            300: "#6b7788",
            400: "#3a4960",
            500: "#091c38",
            600: "#07162d",
            700: "#051122",
            800: "#040b16",
            900: "#02060b"
        },
        greenAccent: {
            100: "#dbf5ee",
            200: "#b7ebde",
            300: "#94e2cd",
            400: "#70d8bd",
            500: "#4cceac",
            600: "#3da58a",
            700: "#2e7c67",
            800: "#1e5245",
            900: "#0f2922"
        },
        redAccent: {
            100: "#f8dcdb",
            200: "#f1b9b7",
            300: "#e99592",
            400: "#e2726e",
            500: "#db4f4a",
            600: "#af3f3b",
            700: "#832f2c",
            800: "#58201e",
            900: "#2c100f"
        },
        blueAccent: {
            100: "#d2d9f4",
            200: "#a4b3ea",
            300: "#778cdf",
            400: "#4966d5",
            500: "#1c40ca",
            600: "#1633a2",
            700: "#3e4396",
            800: "#0b1a51",
            900: "#060d28"
        },
    } : {
        gray: {
            100: "#16191c",
            200: "#2d3238",
            300: "#434a54",
            400: "#5a6370",
            500: "#707c8c",
            600: "#8d96a3",
            700: "#a9b0ba",
            800: "#c6cbd1",
            900: "#e2e5e8",
        },
        primary: {
            100: "#02060b",
            200: "#040b16",
            300: "#051122",
            400: "#f2f0f0",
            500: "#13033af0",
            600: "#3a4960",
            700: "#6b7788",
            800: "#9da4af",
            900: "#ced2d7",
        },
        greenAccent: {
            100: "#0f2922",
            200: "#1e5245",
            300: "#2e7c67",
            400: "#3da58a",
            500: "#4cceac",
            600: "#70d8bd",
            700: "#94e2cd",
            800: "#b7ebde",
            900: "#dbf5ee",
        },
        redAccent: {
            100: "#2c100f",
            200: "#58201e",
            300: "#832f2c",
            400: "#af3f3b",
            500: "#db4f4a",
            600: "#e2726e",
            700: "#e99592",
            800: "#f1b9b7",
            900: "#f8dcdb",
        },
        blueAccent: {
            100: "#151632",
            200: "#2a2d64",
            300: "#3e4396",
            400: "#535ac8",
            500: "#6870fa",
            600: "#868dfb",
            700: "#a4a9fc",
            800: "#c3c6fd",
            900: "#e1e2fe",
        },
    }
});


// Material UI theme settings

export const themeSettings = (mode: 'dark' | 'light'): ThemeOptions => {
    const colors = tokens(mode);

    return {
        palette: {
            mode: mode,
            ...(mode === 'dark') ? {
                primary: {
                    main: colors.primary[500]
                },
                secondary: {
                    main: colors.greenAccent[500],
                },
                neutral: {
                    dark: colors.gray[700],
                    main: colors.gray[500],
                    light: colors.gray[100]
                },
                background: {
                    default: colors.primary[500],
                }
            } : {
                primary: {
                    main: colors.primary[100]
                },
                secondary: {
                    main: colors.greenAccent[500],
                },
                neutral: {
                    dark: colors.gray[700],
                    main: colors.gray[500],
                    light: colors.gray[100]
                },
                background: {
                    default: '#fcfcfc',
                }
            }
        },
        typography: {
            fontFamily: ['Source Sans 3', "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 40
            },
            h2: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 32
            },
            h3: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 24
            },
            h4: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 20
            },
            h5: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 16
            },
            h6: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 14
            },
        }
    }
}

// Context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => { }
});

export const useMode = () => {
    const [mode, setMode] = useState<'dark' | 'light'>('light');

    const colorMode = useMemo(() => ({
        toggleColorMode: () =>
            setMode((prev: 'dark' | 'light') => (prev === 'light' ? 'dark' : 'light')),
    }), [])

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return {theme, colorMode};
}
import { Contact } from "Models/contact";
import { useEffect, useState } from "react";
import { addMonths, subMonths, differenceInDays, format, compareAsc } from 'date-fns'
import './HorizontalContactTimeline.css';
import { Avatar, AvatarProps, Badge, Box, Chip, Typography, styled } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { LifecycleStageActivity } from "Models/lifecycle-stage-activity";
import { EmailActivity } from "Models/email-activity";
import { NoteActivity } from "Models/note-activity";
import { TaskActivity } from "Models/task-activity";
import { MeetingActivity } from "Models/meeting-activity";
import { CallActivity } from "Models/call-activity";
import { Dictionary, groupBy } from 'lodash';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import { ContactView } from "Models/contact-view";
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { Direction } from "Models/direction";
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { Activity } from "Models/activity";

export interface ContactTimelineOptions {
    marketingTouchpointsEnabled: undefined | boolean;
    salesTouchpointsEnabled: undefined | boolean;
}
export interface HorizontalContactTimelineProps {
    contact: ContactView;
    rangeMinDate: Date;
    rangeMaxDate: Date;
    dealCreateDate: Date;
    options: ContactTimelineOptions;
}

const DarkTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#211540",
        border: "1px solid #B5C9DD",
        color: '#B5C9DD',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        backgroundColor: "#211540",
        color: '#B5C9DD',
    },
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#B5C9DD",
        color: '#211540',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        backgroundColor: "transparent",
        color: '#B5C9DD',
    },
}));

export function HorizontalContactTimeline({ contact, rangeMinDate: graphMinDate, rangeMaxDate: graphMaxDate, dealCreateDate, options }: HorizontalContactTimelineProps) {
    const [stages, setStages] = useState<Array<LifecycleStageActivity>>([]);
    const [groupedActivities, setGroupedActivities] = useState<Dictionary<(EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity)[]>>();


    const [dottedWidth, setDottedWidth] = useState(0);

    useEffect(() => {
        if (contact) {
            let difference = differenceInDays(dealCreateDate, graphMinDate);
            let totalDays = differenceInDays(graphMaxDate, graphMinDate);
            let dotWidth = (difference / totalDays) * 100;
            setDottedWidth(dotWidth);

            // Set lifecycle stages
            const filteredStages = contact?.lifecycleStageHistory.filter((stage) => {
                return compareAsc(new Date(stage.timestamp), graphMinDate) === 1 && compareAsc(graphMaxDate, new Date(stage.timestamp)) === 1;
            });
            const stages: Array<LifecycleStageActivity> = filteredStages.map((stage) => {
                let createdDate = new Date(stage.timestamp);
                let diff = differenceInDays(createdDate, graphMinDate);
                let dotWidth = (diff / totalDays) * 100;
                return {
                    ...stage,
                    type: 'stage',
                    width: dotWidth
                }
            });
            setStages(stages);

            let activities = new Array<EmailActivity | NoteActivity | TaskActivity | MeetingActivity | CallActivity>();
            // Set all activities

            if (contact?.calls?.length > 0) {
                let filteredCalls = contact?.calls?.filter((call) => {
                    return compareAsc(new Date(call.createDate), graphMinDate) === 1 &&
                        compareAsc(graphMaxDate, new Date(call.createDate)) === 1;
                });
                if (!options.marketingTouchpointsEnabled) {
                    filteredCalls = filteredCalls.filter((call) => {
                        return call.direction !== Direction.Inbound
                    });
                }

                if (!options.salesTouchpointsEnabled) {
                    filteredCalls = filteredCalls.filter((call) => {
                        return call.direction !== Direction.Outbound
                    });
                }

                activities = activities.concat(filteredCalls.map((call) => {
                    let createdDate = new Date(call.createDate);
                    let diff = differenceInDays(createdDate, graphMinDate);
                    let width = (diff / totalDays) * 100;
                    return {
                        ...call,
                        type: 'call',
                        width,
                        touchpointType: call.direction === Direction.Inbound ? "Marketing" : "Sales"
                    } as CallActivity;
                }));
            }
            if (contact?.meetings?.length > 0) {
                let filteredMeetings = contact.meetings.filter((meeting) => {
                    return compareAsc(new Date(meeting.createDate), graphMinDate) === 1 && compareAsc(graphMaxDate, new Date(meeting.createDate)) === 1;
                });
                if (!options.salesTouchpointsEnabled) {
                    filteredMeetings = filteredMeetings.filter((meeting) => {
                        return meeting.outcome !== '  COMPLETED';
                    });
                }
                activities = activities.concat(filteredMeetings.map((meeting) => {
                    let createdDate = new Date(meeting.createDate);
                    let diff = differenceInDays(createdDate, graphMinDate);
                    let width = (diff / totalDays) * 100;
                    return {
                        ...meeting,
                        type: 'meeting',
                        width,
                    } as MeetingActivity
                }));
            }
            if (contact?.emails?.length > 0) {
                let filteredEmails = contact.emails.filter((email) => {
                    return compareAsc(new Date(email.createDate), graphMinDate) === 1 && compareAsc(graphMaxDate, new Date(email.createDate)) === 1;
                });
                if (!options.marketingTouchpointsEnabled) {
                    filteredEmails = filteredEmails.filter((email) => {
                        return email.direction !== Direction.Inbound
                    });
                }

                if (!options.salesTouchpointsEnabled) {
                    filteredEmails = filteredEmails.filter((email) => {
                        return email.direction !== Direction.Outbound
                    });
                }
                const emailsGrouped = groupBy(filteredEmails, (email) => {
                    return email.title;
                });

                const emailActivityGroupedByTitle = Object.keys(emailsGrouped).map((title) => {
                    if (emailsGrouped[title].length === 1) {
                        const email = emailsGrouped[title][0];
                        let createdDate = new Date(email.createDate);
                        let diff = differenceInDays(createdDate, graphMinDate);
                        let width = (diff / totalDays) * 100;
                        return {
                            ...email,
                            type: 'email',
                            width,
                            isThread: false,
                            touchpointType: email.direction === Direction.Inbound ? "Marketing" : "Sales"
                        } as EmailActivity
                    } else {
                        const minEmailDate = Math.min(...emailsGrouped[title].map((email) => new Date(email.createDate).getTime()));
                        const maxEmailDate = Math.max(...emailsGrouped[title].map((email) => new Date(email.createDate).getTime()));
                        const email = emailsGrouped[title][0];
                        let createdDate = new Date(email.createDate);
                        let diff = differenceInDays(createdDate, graphMinDate);
                        let width = (diff / totalDays) * 100;
                        return {
                            ...email,
                            type: 'email',
                            width,
                            range: `${format(new Date(minEmailDate), "MM/dd/yyyy")} - ${format(new Date(maxEmailDate), "MM/dd/yyyy")}`,
                            widthOverride: "153px",
                            isThread: true
                        }
                    }
                }) as Array<EmailActivity>;

                // Group emails by date
                const emailActivityGroupedByDate = groupBy(emailActivityGroupedByTitle, (email) => {
                    return new Date(email.createDate).toISOString().substring(0, 10);
                });

                const finalEmailList = Object.keys(emailActivityGroupedByDate).map((date) => {
                    const firstEmail = emailActivityGroupedByDate[date][0];

                    if (emailActivityGroupedByDate[date].length === 1) {
                        return {
                            ...emailActivityGroupedByDate[date][0],
                            count: 0
                        }
                    } else {
                        return {
                            ...firstEmail,
                            isThread: false,
                            count: emailActivityGroupedByDate[date].length
                        }
                    }
                }) as Array<EmailActivity>;

                activities = activities.concat(finalEmailList);
            }
            if (contact?.tasks?.length > 0) {
                const filteredTasks = contact.tasks.filter((task) => {
                    return compareAsc(new Date(task.createDate), graphMinDate) === 1 && compareAsc(graphMaxDate, new Date(task.createDate)) === 1;
                });
                activities = activities.concat(filteredTasks.map((task) => {
                    let createdDate = new Date(task.createDate);
                    let diff = differenceInDays(createdDate, graphMinDate);
                    let width = (diff / totalDays) * 100;
                    return {
                        ...task,
                        type: 'task',
                        width,
                    }
                }));
            }
            if (contact?.notes?.length > 0) {
                const filteredNotes = contact.notes.filter((note) => {
                    return compareAsc(new Date(note.createDate), graphMinDate) === 1 && compareAsc(graphMaxDate, new Date(note.createDate)) === 1;
                });
                activities = activities.concat(filteredNotes.map((note) => {
                    let createdDate = new Date(note.createDate);
                    let diff = differenceInDays(createdDate, graphMinDate);
                    let width = (diff / totalDays) * 100;
                    return {
                        ...note,
                        type: 'note',
                        width,
                    }
                }));
            }
            const groupedActivities = groupBy(activities, (activity) => {
                const createDate = new Date(activity.createDate);
                const tolerance = 5 * 24 * 60 * 60 * 1000;
                const roundedDate = new Date(Math.round(createDate.getTime() / tolerance) * tolerance);
                return roundedDate.toISOString().substring(0, 10);
            });
            setGroupedActivities(groupedActivities);
        }
    }, [contact, dealCreateDate, graphMaxDate, graphMinDate, options.marketingTouchpointsEnabled, options.salesTouchpointsEnabled])

    const renderTooltip = (activity: Activity) => {
        switch (activity.type) {
            case 'note':
                return <Box display="flex" flexDirection="column">
                    <Typography variant="h6" fontWeight="bold">{activity.type.toUpperCase()}</Typography>
                    <Box dangerouslySetInnerHTML={{ __html: (activity as NoteActivity).title }}></Box>
                </Box>
                break;
            case 'call':
            case 'meeting':
            case 'task':
                return <Box display="flex" flexDirection="column">
                    <Typography variant="h6" fontWeight="bold">{activity.type.toUpperCase()}</Typography>
                    <Box>{(activity as CallActivity).title}</Box>
                    <Box dangerouslySetInnerHTML={{ __html: (activity as CallActivity).content }}></Box>
                </Box>
                break;
            default:
            case 'email':
                return <Box display="flex" flexDirection="column">
                    <Typography variant="h6" fontWeight="bold">{activity.type.toUpperCase()}</Typography>
                    <Box>{(activity as EmailActivity).title}</Box>
                </Box>
                break;
        }
    }

    return <>
        <div className="timeline-main">
            <Typography marginTop="20px" marginLeft="20px" gutterBottom fontSize="16px" fontStyle="normal" fontWeight="700" letterSpacing="1.12px" lineHeight="normal" color="#B5C9DD">
                {`${contact.firstName} ${contact.lastName}, `}
                <Box component="span" fontSize="16px" fontWeight="400" color="#B5C9DD">Title: {contact.jobTitle}</Box>
            </Typography>
            <Box display="flex" flex="1" flexDirection="row" alignItems="center">
                <Box margin="10px" marginRight="0"><Avatar {...contact.avatarProps} /></Box>
                <Box className="timeline">
                    <Box className="timeline-fragment dotted" style={{ width: `${dottedWidth}%` }}></Box>
                    <Box className="timeline-fragment solid" style={{ width: `calc(${100 - dottedWidth}% - 8px)` }}></Box>
                    <Box className="dot">●</Box>
                    {stages?.map((stage, index) => {
                        return <>{stage.stageTitle && <DarkTooltip title={format(new Date(stage.timestamp), 'MM/dd/yyyy')} placement="top" arrow key={index} >
                            <Chip label={stage.stageTitle} style={{ left: `${stage.width}%` }} className="stage" />
                        </DarkTooltip>}</>
                    })}
                    {groupedActivities && Object.keys(groupedActivities).map((obj, index) => {
                        return <div key={index}>
                            {obj &&
                                <Box className="pill-wrapper" style={{ left: `${groupedActivities[obj][0].width}%`, top: (index % 2 === 0) ? "inherit" : "30px", bottom: (index % 2 === 0) ? "30px" : 0 }}>
                                    {(index % 2 !== 0) && <>
                                        <Box className="activity-dot">●</Box>
                                        <Box className="pill-line" style={{ rotate: "180deg", width: (groupedActivities[obj].some((act) => (act.type === 'email' && (act as EmailActivity).isThread)) ? '154px' : '90px') }}></Box>
                                    </>}
                                    {groupedActivities[obj].map((activity, index) => {
                                        return <Box key={index} display="flex" justifyContent="center">
                                            <LightTooltip title={renderTooltip(activity)} placement="right" arrow>
                                                <Box style={{ marginTop: (index % 2 !== 0 && index > 0) ? "4px" : "0px", marginBottom: (index % 2 === 0 && index > 0) ? "4px" : "0px", width: (activity.type === 'email' && (activity as EmailActivity).isThread ? (activity as EmailActivity).widthOverride : '90px') }} className="pill" >
                                                    {activity.type === 'email' && <Avatar sx={{ width: 15, height: 15, fontWeight: 700, fontSize: "9px", backgroundColor: "#756798" }}>{(activity as EmailActivity).touchpointType === 'Sales' ? 'S' : 'M'}</Avatar>}
                                                    {activity.type === 'call' && <Avatar sx={{ width: 15, height: 15, fontWeight: 700, fontSize: "9px", backgroundColor: "#756798" }}>{(activity as CallActivity).touchpointType === 'Sales' ? 'S' : 'M'}</Avatar>}
                                                    {activity.type === 'meeting' && <Avatar sx={{ width: 15, height: 15, fontWeight: 700, fontSize: "9px", backgroundColor: "#756798" }}>{(activity as MeetingActivity).outcome === 'COMPLETED' ? 'S' : 'M'}</Avatar>}
                                                    <Box className="date-wrapper">
                                                        {activity.type === 'email' && (activity as EmailActivity).isThread && <Box className="date">{(activity as EmailActivity).range}</Box>}
                                                        {activity.type === 'email' && !(activity as EmailActivity).isThread && <Box className="date">{format(new Date(activity.createDate), 'MM/dd/yyyy')}</Box>}
                                                        {activity.type !== 'email' && <Box className="date">{format(new Date(activity.createDate), 'MM/dd/yyyy')}</Box>}
                                                        {activity.type === 'email' && (activity as EmailActivity).isThread && <DynamicFeedIcon className="timeline-icon" style={{ color: "#07B642" }} />}
                                                        {activity.type === 'email' && !(activity as EmailActivity).isThread && (activity as EmailActivity).count === 0 && <EmailOutlinedIcon className="timeline-icon" style={(activity as EmailActivity).touchpointType === 'Sales' ? { color: "#5AB177" } : { color: "#0690F9" }} />}
                                                        {activity.type === 'email' && !(activity as EmailActivity).isThread && (activity as EmailActivity).count > 0 && <FilterNoneIcon color="action" className="timeline-icon" style={(activity as EmailActivity).touchpointType === 'Sales' ? { color: "#5AB177" } : { color: "#0690F9" }} />}
                                                        {activity.type === 'meeting' && <GroupsOutlinedIcon className="timeline-icon" style={{ color: "#07B642" }} />}
                                                        {activity.type === 'task' && <AssignmentTurnedInOutlinedIcon className="timeline-icon" style={{ color: "#07B642" }} />}
                                                        {activity.type === 'note' && <EditNoteOutlinedIcon className="timeline-icon" style={{ color: "#07B642" }} />}
                                                        {activity.type === 'call' && <PhoneOutlinedIcon className="timeline-icon" style={(activity as CallActivity).touchpointType === 'Sales' ? { color: "#5AB177" } : { color: "#0690F9" }} />}
                                                    </Box>
                                                </Box>
                                            </LightTooltip>
                                        </Box>
                                    })}
                                    {(index % 2 === 0) && <>
                                        <Box className="pill-line" style={{ rotate: "180deg", width: (groupedActivities[obj].some((act) => (act.type === 'email' && (act as EmailActivity).isThread)) ? '154px' : '90px') }}></Box>
                                        <Box className="activity-dot">●</Box>
                                    </>
                                    }
                                </Box>}
                        </div>
                    })}
                </Box>
            </Box>
        </div>
    </>
}
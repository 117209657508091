import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from 'auth0-provider-with-navigate';
import { Provider } from 'react-redux';
import { store } from 'State/store';
import { Config, configManager } from 'Helpers/config-manager';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

fetch('/config.json').then((res) => res.json()).then((config: Config) => {
  configManager.setConfig(config);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <App />
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { ColorModeContext, tokens } from '../../theme';
import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import MenuButton from 'Components/MenuButton';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';

const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const { logout } = useAuth0();
    const navigate = useNavigate();

    const userMenuItemClicked = (setting: string) => {
        if (setting.toLowerCase() === 'logout') {
            logout();
        }
    }

    const settingsClicked = () => {
        navigate('integrations');
    }

    const homeClicked = () =>{
        navigate('home');
    }

    return <>
        <Box display="flex" justifyContent="space-between" p={1} sx={{ backgroundColor: `${colors.primary[500]}` }}>
            <Box display="flex">
                <Typography variant="h6" noWrap component="div">
                    <img src='/logo.png' alt={"RevOptica"} height="40px" width="140px" />
                </Typography>
                <Box display="flex" width="279px" justifyContent="center">
                    <Button sx={{ color: 'white', display: 'block' }} onClick={homeClicked}>
                        Home
                    </Button>
                    <Button sx={{ color: 'white', display: 'block' }}>
                        My Dashboard
                    </Button>
                </Box>
            </Box>
            <Box display="flex">
                <IconButton aria-label="settings" color="primary" onClick={settingsClicked} sx={{ cursor: "pointer" }}>
                    <SettingsIcon sx={{ color: '#FFF' }} />
                </IconButton>
                {/* <MenuButton
                    icon={NotificationsNoneOutlinedIcon}
                    items={['Logout']}
                    onMenuItemClick={userMenuItemClicked} /> */}
                <MenuButton
                    icon={PersonOutlinedIcon}
                    items={['Logout']}
                    onMenuItemClick={userMenuItemClicked} />
            </Box>
        </Box>
    </>
}

export default React.memo(Topbar);
import AppRoutes from 'Components/AppRoutes';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from 'Components/PageLoader';
import { useEffect } from 'react';
import { securityClient } from 'securityClient';
import { useAppDispatch } from 'State/hooks';
import { setCompany } from 'State/companySlice';
import { AppMetadata } from 'Models/app-metadata';
import { ColorModeContext, useMode } from 'theme';
import UnAuthorizedError from 'Components/UnAuthorizedError';
import Topbar from 'Pages/Global/Topbar';

function App() {
  const { theme, colorMode } = useMode();
  const { isLoading, getAccessTokenSilently, getIdTokenClaims, isAuthenticated, error } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    getAccessTokenSilently().then(async () =>{
      securityClient.setAccessTokenSilently(getAccessTokenSilently);
      let idToken = await getIdTokenClaims();
      if (idToken) {
        const appMetadata = idToken[`http://revoptica.com/app_metadata`] as AppMetadata;
        if (appMetadata.companyId) {
          dispatch(setCompany(appMetadata.companyId));
        }
      }
    });
    
  }, [dispatch, getAccessTokenSilently, getIdTokenClaims]);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  if (error) {
    return <UnAuthorizedError error={error} />
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            {isAuthenticated && <Topbar/>}
            <AppRoutes />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;


class ConfigManager {
  private static instance: ConfigManager;
  private config?: Config;

  constructor() {
    if (ConfigManager.instance) {
      throw new Error("Error: Instantiation failed: Use BaseConfig.getInstance() instead of new.");
    }
    ConfigManager.instance = this;
  }

  static getInstance() {
    if (!ConfigManager.instance) {
      ConfigManager.instance = new ConfigManager();
    }
    return ConfigManager.instance;
  }

  getConfig() {
    return this.config;
  }

  setConfig(config: Config){
    this.config = config;
  }
}

export const configManager = ConfigManager.getInstance();

export interface Config {
  api: ApiConfig;
  auth0: Auth0Config;
  hubspot: HubspotConfig;
  salesforce: SalesforceConfig;
}

interface ApiConfig {
  url: string;
  httpProtocol: string;
  wsProtocol: string;
}

interface Auth0Config {
  domain: string;
  clientId: string;
  callbackUrl: string;
  audience: string;
}

interface HubspotConfig {
  clientId: string;
  redirectUri: string;
  scopes: string;
  baseAuthUrl: string;
}

interface SalesforceConfig {
  baseAuthUrl: string;
  clientId: string;
  redirectUri: string;
  scopes: string;
}

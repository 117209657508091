import { api } from './api'
import { Pipeline } from 'Models/pipeline';

export const pipelineApi = api.injectEndpoints({
    endpoints: (build) => ({
        getPipelines: build.query<Array<Pipeline>, string>({
            query: (companyId) => ({ url: `integration/core/company/${companyId}/pipeline` }),
            providesTags: ['Pipelines'],
        })
    })
});

export const {
    useGetPipelinesQuery
} = pipelineApi
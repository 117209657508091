import { Alert, AlertTitle } from "@mui/material";

export type unAuthorizedProps = {
    error?: Error;
}

export default function UnAuthorizedError({ error }: unAuthorizedProps) {
    return <>
        <Alert severity="error">
            <AlertTitle>Error: 401 Unauthorized</AlertTitle>
            {error?.message && <div>{error.message}</div>}
            {!error && <div>Oops! It seems you are unauthorized to access this resource. Please make sure you have the necessary permissions or credentials to proceed.</div>}

            If you believe this is an error, kindly contact the administrator or support team for assistance.

            Thank you!
        </Alert>
    </>
}
import { IconButton, Menu, MenuItem, SvgIconTypeMap, useTheme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useState } from "react";
import { tokens } from "theme";

export type MenuButtonProps = {
    icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string; };
    items: string[];
    onMenuItemClick: (link: string) => void;
}

export default function MenuButton(props: MenuButtonProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const Wrapper = props.icon;
    const listItems = props.items.map((link) =>
        <MenuItem key={link} onClick={(event) => handleClose(event, link)}>{link}</MenuItem>
    );

    const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, link: string) => {
        setAnchorEl(null);
        if (link) {
            props.onMenuItemClick(link)
        }
    };

    return (
        <>
            <IconButton onClick={handleMenu} sx={{ color: '#FFF' }}>
                {<Wrapper />}
            </IconButton>
            <Menu
                sx={{ "& .MuiPaper-root": { backgroundColor: colors.primary[400] } }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {listItems}
            </Menu>
        </>
    )
}
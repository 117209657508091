import { Owner } from 'Models/owner';
import { api } from './api'

export const ownerApi = api.injectEndpoints({
    endpoints: (build) => ({
        getOwners: build.query<Array<Owner>, string>({
            query: (companyId) => ({ url: `integration/core/company/${companyId}/owner` }),
            providesTags: ['Owners'],
        })
    })
});

export const {
    useGetOwnersQuery
} = ownerApi
import { Box, Button, ButtonBase, Grid, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from "react";
import { Integration } from "Models/integration";
import { IntegrationType } from "Models/integration-type";
import { useGetIntegrationsQuery, useSyncMutation } from "Services/company";
import { useAppSelector } from "State/hooks";
import { configManager } from "Helpers/config-manager";
import { useNavigate } from "react-router-dom";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});
export default function Integrations() {
    const config = configManager.getConfig();
    const companyId = useAppSelector((state) => state.company.companyId);
    const [enableHubspot, setEnableHubspot] = useState(false);
    const [enableSalesforce, setEnableSalesforce] = useState(false);
    const [integrations, setIntegrations] = useState<Array<Integration>>([]);
    const { data: integrationData } = useGetIntegrationsQuery(companyId, { skip: !companyId });
    const [syncMutation] = useSyncMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (integrationData) {
            if (integrationData.length > 0) {
                setIntegrations(integrationData);
                const hubSpot = integrationData.find((int: Integration) => int.type === IntegrationType.hs);
                if (!hubSpot) {
                    setEnableHubspot(true);
                }
                const salesforce = integrationData.find((int: Integration) => int.type === IntegrationType.sf);
                if (!salesforce) {
                    setEnableSalesforce(true);
                }
            } else {
                setEnableHubspot(true);
                setEnableSalesforce(true);
            }
        }
    }, [integrationData])

    const integrateWithHubSpot = () => {
        if (!config?.hubspot.clientId || !config.hubspot.scopes || !config.hubspot.redirectUri) {
            return;
        };

        let scopes = '';

        if (config.hubspot.scopes) {
            scopes = (config.hubspot.scopes.split(/ |, ?|%20/)).join(' ');
        }

        const authUrl =
            `${config.hubspot.baseAuthUrl}` +
            `?client_id=${encodeURIComponent(config?.hubspot.clientId)}` + // app's client ID
            `&scope=${encodeURIComponent(scopes)}` + // scopes being requested by the app
            `&redirect_uri=${encodeURIComponent(config.hubspot.redirectUri)}`; // where to send the user after the consent page

        window.location.href = authUrl;
    }

    const synchronize = async () => {
        syncMutation({ companyId: companyId, reSync: true }).then(() => {
            navigate('/home');
        });
    }

    const integrateWithSalesforce = () => {
        if (!config?.salesforce.clientId || !config.salesforce.scopes || !config.salesforce.redirectUri) {
            return;
        };

        let scopes = '';

        if (config.salesforce.scopes) {
            scopes = (config.salesforce.scopes.split(/ |, ?|%20/)).join(' ');
        }

        const authUrl =
            `${config.salesforce.baseAuthUrl}` +
            `?client_id=${encodeURIComponent(config.salesforce.clientId)}` + // app's client ID
            `&scope=${encodeURIComponent(scopes)}` + // scopes being requested by the app
            `&redirect_uri=${encodeURIComponent(config.salesforce.redirectUri)}` + // where to send the user after the consent page
            `&response_type=code`;


        console.log(authUrl);

        window.location.href = authUrl;
    }
    return <>
        <Box display="flex" flex="1" flexDirection="row" margin="20px">
            <Paper
                sx={{
                    display: "flex",
                    mr: 5,
                    p: 2,
                    maxWidth: 500,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item>
                        <ButtonBase sx={{ width: 128, height: 128 }}>
                            <Img alt="complex" src="/hubspot.svg" />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                            <Grid item xs>
                                <Typography gutterBottom variant="subtitle1" component="div">
                                    Integrate with Hubspot
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Streamline your marketing and sales efforts by seamlessly integrating with HubSpot, a powerful platform that empowers you to attract, engage, and delight customers with ease.
                                </Typography>
                            </Grid>
                            <Grid item className="flex justify-between">
                                <Button variant="contained" disabled={!enableHubspot} onClick={integrateWithHubSpot}>Integrate with HubSpot</Button>
                                {!enableHubspot && <Button variant="contained" disabled={enableHubspot} onClick={synchronize}>Sync</Button>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Paper
                sx={{
                    display: "flex",
                    p: 2,
                    maxWidth: 500,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item>
                        <ButtonBase sx={{ width: 128, height: 128 }}>
                            <Img alt="complex" src="/salesforce.png" />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography gutterBottom variant="subtitle1" component="div">
                                Integrate with Salesforce
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Salesforce is a cloud-based customer relationship management (CRM) platform that helps businesses manage their sales, marketing, service, and customer data in one unified system.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" disabled={!enableSalesforce} onClick={integrateWithSalesforce}>Integrate with Salesforce</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box>

    </>
}
import { IntegrationType } from "./integration-type";

export interface SocketEvent {
  data?: IntegrationData;
  type: EventType;
  companyId: string;
}

export interface IntegrationData {
  integrationType: IntegrationType;
  eventSubject: string;
  data: any;
}

export enum EventType {
  Initialize,
  PostIntegrationEventToQueue,
  BroadcastMessageToRoom,
}
import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    companyId: '',
  },
  reducers: {
    setCompany: (state, action: PayloadAction<string>) =>{
        state.companyId = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { setCompany } = companySlice.actions

export default companySlice.reducer
import { useAuth0 } from "@auth0/auth0-react";
import { configManager } from "Helpers/config-manager";
import { AppMetadata } from "Models/app-metadata";
import { useInitializeSalesforceMutation } from "Services/company";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function SFCallback() {
    const navigate = useNavigate();
    const [companyId, setCompanyId] = useState('');
    const [authCode, setAuthCode] = useState('');
    let [searchParams] = useSearchParams();
    const { getIdTokenClaims } = useAuth0();
    const [initializeSalesforceIntegration] = useInitializeSalesforceMutation();

    useEffect(() => {
        if (authCode && companyId) {
            initializeSalesforceIntegration({ companyId, authCode }).then(() => {
                console.log("callback navigate fired: ", companyId);
                navigate('/');
            });
        }
    }, [authCode, companyId, initializeSalesforceIntegration, navigate]);

    useEffect(() => {
        const config = configManager.getConfig();
        getIdTokenClaims().then(async (idToken) => {
            if (idToken) {
                const appMetadata = idToken[`http://revoptica.com/app_metadata`] as AppMetadata;
                if (appMetadata.companyId) {
                    setCompanyId(appMetadata.companyId);
                }
                const authCode = searchParams.get('code');
                if (authCode) {
                    setAuthCode(authCode);
                }
            }
        });
    }, [getIdTokenClaims, searchParams])

    return <>Loading...</>
}
import { api } from './api'
import { EventType, SocketEvent } from 'Models/socket-event';
import { io } from 'socket.io-client';
import { configManager } from 'Helpers/config-manager';

export type Channel = 'redux' | 'incoming'

export const messagesApi = api.injectEndpoints({
    endpoints: (build) => ({
        getMessages: build.query<any, string>({
            query: (companyId: string) => `messages/company/${companyId}`,
            async onCacheEntryAdded(
                companyId,
                { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
            ) {
                const config = configManager.getConfig();
                // create a websocket connection when the cache subscription starts
                const socket = io(`${config?.api.wsProtocol}://${config?.api.url}`);
                try {
                    // wait for the initial query to resolve before proceeding
                    await cacheDataLoaded

                    const socketEvent: SocketEvent = {
                        companyId: companyId,
                        type: EventType.Initialize
                    }
                    socket.emit("events", socketEvent, (response: any) => {
                        console.log(response);
                    });

                    socket.on('incoming', (message: any) => {
                        console.log("incoming", message);
                        updateCachedData((draft) => {
                            draft.push(message);
                        });
                    });

                    socket.io.on("reconnect", () => {
                        console.log("reconnected");
                        socket.emit("events", socketEvent);
                    });
                } catch {
                    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
                    // in which case `cacheDataLoaded` will throw
                }
                // cacheEntryRemoved will resolve when the cache subscription is no longer active
                await cacheEntryRemoved
                // perform cleanup steps once the `cacheEntryRemoved` promise resolves
                socket.close()
            },
        }),
    })
});



export const {
    endpoints: { getMessages },
} = messagesApi

export const {
    useGetMessagesQuery
} = messagesApi